<template>
  <div class="flex flex-col gap-3 h-full mt-4">
    <h4>Clearing Schema</h4>
    <div
      :style="{ minHeight: rowHeight, maxHeight: rowHeight, overflow: 'auto' }"
    >
      <vs-table :data="schemas">
        <template slot="thead">
          <vs-th> # </vs-th>
          <vs-th>
            {{ generateTypeName() }}
          </vs-th>
          <vs-th> {{ generateTypeName() }} Remaining Before </vs-th>
          <vs-th> Debit Note </vs-th>
          <vs-th> Debit Note Remaining Before </vs-th>
          <vs-th> Paid </vs-th>
          <vs-th> {{ generateTypeName() }} Remaining After </vs-th>
          <vs-th> Debit Note Remaining After </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.id">
              {{ tr.id }}
            </vs-td>

            <vs-td :data="tr.invoice_code">
              {{
                poType !== "CN Supplier" ? tr.invoice_code : tr.cn_supplier_code
              }}
            </vs-td>

            <vs-td>
              {{
                priceFormat(
                  poType !== "CN Supplier"
                    ? tr.invoice_before_clearing_value
                    : tr.cn_supplier_before_clearing_value
                )
              }}</vs-td
            >

            <vs-td :data="tr.debit_note_code">
              {{ tr.debit_note_code }}
            </vs-td>

            <vs-td>
              {{ priceFormat(tr.debit_note_before_clearing_value) }}</vs-td
            >

            <vs-td :data="tr.paid_value">
              {{ priceFormat(tr.paid_value) }}
            </vs-td>

            <vs-td :data="tr.outstanding_value">
              {{ priceFormat(tr.outstanding_value) }}
            </vs-td>

            <vs-td :data="tr.debit_note_remaining_value">
              {{ priceFormat(tr.debit_note_remaining_value) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

    <div class="flex flex-row justify-end gap-4">
      <vs-button class="sticky" color="danger" @click="backTab">Back</vs-button>
      <vs-button class="sticky" @click="clearing">Clearing</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schemas: [],
      rowHeight: "",
    };
  },
  mounted() {
    this.rowHeight = `calc(${window.innerHeight}px - 280px)`;
    this.clearingSchema();
  },
  props: {
    selectedProposal: Array,
    poType: String,
  },
  // watch: {
  //   currentTab() {
  //     if (this.currentTab === 1) {
  //       this.clearingSchema();
  //     }
  //   },
  // },
  methods: {
    backTab() {
      this.$emit("backTab");
    },
    clearing() {
      console.log("clearing", this.schemas);
      this.$emit("clearing", this.schemas);
    },
    generateTypeName() {
      if (this.poType !== "CN Supplier") {
        return "Invoice";
      } else {
        return "CN Supplier";
      }
    },
    async clearingSchema() {
      try {
        let selectedDN = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.selectedDebitNotes)
        );

        if (selectedDN.length === 0) {
          this.$vs.notify({
            title: "Clearing",
            text: "Please select at least one debit note",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          throw new Error("Please select at least one debit note");
        }

        // selectedDN.sort((a, b) => a.value - b.value);
        console.log("selected DN", selectedDN);

        const selectedProposalPaidNow = JSON.parse(
          JSON.stringify(this.$store.state.clearingDN.proposalPaidNows)
        );

        selectedProposalPaidNow.sort(
          (a, b) => a.remaining_value - b.remaining_value
        );

        if (
          !selectedProposalPaidNow.every(
            (item) => item.value <= item.remaining_value
          )
        ) {
          this.$vs.notify({
            title: "Clearing",
            text: "Paid now cannot be more than purchase payment proposal value",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          throw new Error(
            "Paid now cannot be more than purchase payment proposal value"
          );
        }

        console.log("selectedProposalPaidNow", selectedProposalPaidNow);
        console.log("selectedProposal", this.selectedProposal);
        this.$vs.loading();
        // /purchase-payment/clearing-schema
        const body = {
          debit_note_ids: selectedDN.map((dn) => dn.id),
        };

        if (this.poType !== "CN Supplier") {
          body.invoices = selectedProposalPaidNow.map((proposalPaidNow) => {
            const proposal = this.selectedProposal.find(
              (item) => item.ID === proposalPaidNow.id
            );
            // this.selectedProposal

            console.log("proposalPaidNow", proposalPaidNow);
            console.log("proposal", proposal);

            return {
              paid_value: proposalPaidNow.value,
              purchase_payment_proposal_id: proposal.ID,
              purchase_invoice_code_internal:
                proposal.PurchaseInvoiceCodeInternal,
              purchase_invoice_code: proposal.PurchaseInvoiceCode,
              purchase_invoice_id: proposal.PurchaseInvoiceID,
            };
          });
        } else {
          body.credit_note_suppliers = selectedProposalPaidNow.map(
            (proposalPaidNow) => {
              const proposal = this.selectedProposal.find(
                (item) => item.ID === proposalPaidNow.id
              );
              // this.selectedProposal

              console.log("proposalPaidNow", proposalPaidNow);
              console.log("proposal", proposal);

              return {
                paid_value: proposalPaidNow.value,
                purchase_payment_proposal_id: proposal.ID,
                credit_note_supplier_id: proposal.CreditNoteSupplierID,
              };
            }
          );
        }

        const url =
          this.poType !== "CN Supplier"
            ? "/api/v1/debit-note/purchase-payment/clearing-schema"
            : "/api/v1/debit-note/purchase-payment/clearing-schema/cn-supplier";

        const resp = await this.$http.post(url, body);
        if (resp.code <= 299) {
          this.$vs.loading.close();
          this.schemas = resp.data;
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Clearing",
            text: "Failed to generate clearing schema",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.backTab();
        }
      } catch (e) {
        console.log(e);
        this.backTab();
      }
    },
  },
};
</script>
